const pageVariants = {
  initial: {
    opacity: 0,
    // scale: 0.8,
    x: "-100vw",
  },
  animate: {
    opacity: 1,
    x: 0,
    // scale: 1,
  },
  exit: {
    opacity: 0,
    x: "100vw",
    // scale: 1.2,
  },
};

export const pageTransitions = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

export default pageVariants;
