import Div100vh from "react-div-100vh";
import { Outlet } from "react-router-dom";
import BottomTab from "../Components/universal/BottomTab";
import Navbar from "../Components/universal/Navbar";

function Base() {
  return (
    <Div100vh>
      <main className="relative flex flex-col w-full lg:w-1/2  h-full overflow-hidden">
        <Navbar />
        <section className="w-full flex-1 flex flex-col overflow-auto">
          <main className="flex-1 overflow-auto">
            <Outlet />
          </main>
          <BottomTab />
        </section>
      </main>
    </Div100vh>
  );
}

export default Base;
