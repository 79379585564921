import Phone from "../../assets/icons/Icon feather-smartphone.svg";
import SoccerIcon from "../../assets/icons/Icon ionic-md-football.svg";
import LiveIcon from "../../assets/icons/Icon material-timer-off.svg";
import Games from "./games/Index";

export default function Home() {
  return (
    <main className="bg-white w-full h-full flex flex-col overflow-y-scroll">
      <section className="h-20 w-full grid grid-cols-4 items-center justify-items-center shadow-xl py-2">
        <a
          href="https://www.kwikbet.co.ke/sportsbook/#/"
          className="flex flex-col justify-center gap-2"
        >
          <img src={SoccerIcon} alt="soccer" className="h-6" />
          <h1 className="text-xs">Sports</h1>
        </a>
        <a
          href="https://www.kwikbet.co.ke/aviator/"
          className="flex flex-col justify-center gap-2"
        >
          <img src={LiveIcon} alt="soccer" className="h-6" />
          <h1 className="text-xs">Kandege</h1>
        </a>
        <a
          href="https://www.kwikbet.co.ke/#/highlights"
          className="flex flex-col justify-center gap-2"
        >
          <img src={Phone} alt="soccer" className="h-6" />
          <h1 className="text-xs">Highlights</h1>
        </a>
        <a
          href="https://storage.googleapis.com/kwikbet/Kwikbet.apk"
          className="flex flex-col justify-center gap-2"
        >
          <img src={Phone} alt="soccer" className="h-6" />
          <h1 className="text-xs">App</h1>
        </a>
      </section>
      {/* <section className="grid grid-cols-5 bg-white text-xs py-4 justify-items-center">
        <Link to="/">Highlights</Link>
        <Link to="/">Upcoming</Link>
        <Link to="/">Top Leagues</Link>
        <Link to="/">Favourites</Link>
        <Link to="/">Countries</Link>
      </section> */}
      <section className="py-4 px-4 flex text-xs justify-between items-center bg-[#F5F5F5]">
        {/* <section className="py-4 px-4 flex text-xs justify-between items-center bg-[#F5F5F5]"> */}
        <h1>Tomorrow</h1>
        <div className="flex gap-2 items-center">
          Sort by:{" "}
          <span className="bg-[#3A3A3A] text-white px-2 py-1 rounded-xl">
            League
          </span>
          <span>Time</span>
        </div>
      </section>
      <section className="py-2 px-4 flex justify-between items-center bg-white border-l-8 border-[#2FBB6E] sticky top-0">
        <h1>Soccer</h1>
        <div className="flex gap-10 items-center">
          <span>1</span>
          <span>x</span>
          <span>2</span>
        </div>
      </section>
      <section className="flex-1 bg-[#F5F5F5]">
        <Games />
      </section>
    </main>
  );
}
