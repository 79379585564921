import { motion } from "framer-motion";
import { publicIpv4 } from "public-ip";
import { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/Logo.png";
import { useAppDispatch } from "../../state/hooks";
import { addUser } from "../../state/reducers/userReducer";
import { handlePostRequest } from "../../utils/post_api";
import { PostData } from "../../utils/request_models";
import pageVariants, { pageTransitions } from "../universal/Animations";
import Loader from "../universal/Loader";

export default function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleLogin = async () => {
    setIsLoggingIn(true);
    const data: PostData<{
      login: string;
      password: string;
      ipAddress: string;
    }> = {
      data: {
        login: mobile.replace("254", "0"),
        password,
        ipAddress: await publicIpv4(),
      },
    };
    handlePostRequest("user/auth", data)
      .then(() => {
        dispatch(
          addUser({
            phoneNumber: mobile.replace("254", "0"),
            isLoggedIn: true,
          }),
        );
        navigate("/");
      })
      .catch(() => {
        toast.error(
          "Something went wrong. Kindly check your credentials and try again.",
          {
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
          },
        );
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  const handleResetPassword = () => {
    navigate("/reset-password");
  };
  const handleViewJackpot = () => {
    navigate("/");
  };

  return (
    <motion.main
      className="bg-primary h-screen w-screen"
      variants={pageVariants}
      transition={pageTransitions}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <header className="w-full grid grid-cols-1 justify-items-center gap-8 py-20">
        <img src={Logo} alt="Logo" className="w-1/3" />
        <h1 className="text-white">Login</h1>
      </header>
      <section className="w-full grid justify-items-center">
        <form className="w-10/12 grid-cols-1 gap-12 text-white">
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2">
            <h1 className="text-sm">Mobile Number</h1>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            {/* <PhoneInput
              country="ke"
              inputStyle={{
                backgroundColor: "#3A0149",
                border: "none",
                height: 56,
                maxWidth: "100%",
              }}
              containerClass=" rounded w-full"
              dropdownStyle={{ color: "black" }}
              // dropdownClass="w-full"
              searchClass="w-12 flex justify-center"
              value={mobile}
              onChange={(e) => setMobile(e)}
            /> */}
            <input
              type="text"
              name="text"
              id=""
              className="h-14 rounded bg-primaryDark  placeholder:text-sm border-none w-full"
              placeholder="Enter your mobile number 2547xxxxxxx"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </label>
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2 mt-4">
            <h1 className="text-sm">Password</h1>
            <input
              type="password"
              name="password"
              id=""
              className="h-14 rounded bg-primaryDark  placeholder:text-sm border-none w-full"
              placeholder="Enter a 4 digit password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label
            htmlFor="LoggedIn"
            className="flex w-full justify-start gap-2 items-center mt-2"
          >
            <input
              type="checkbox"
              name="keepSession"
              id=""
              className="h-4 w-4 text-primaryDark rounded border-2 border-primary"
            />
            <h1 className="text-sm">Keep me Logged In</h1>
          </label>
          <div className="w-full grid grid-cols-1 justify-items-center">
            {!isLoggingIn ? (
              <button
                type="button"
                className="bg-white text-black w-1/3 py-1 rounded mt-4 bg-gradient-to-b from-secondary to-secondaryLight"
                onClick={handleLogin}
              >
                Login
              </button>
            ) : (
              <Loader />
            )}
            <button
              className="text-xs mt-4 text-secondary"
              type="button"
              onClick={handleResetPassword}
            >
              Forgot Your Password?
            </button>
            <button
              className="text-xs mt-4 text-white font-bold"
              type="button"
              onClick={handleViewJackpot}
            >
              View Jackpot?
            </button>
          </div>
        </form>
      </section>

      <Link
        to="/signup"
        className="w-full flex justify-center absolute bottom-8 text-white text-xs"
      >
        Dont have an Account?
        <button type="button" className="text-secondary pl-1">
          Register here
        </button>
      </Link>
    </motion.main>
  );
}
