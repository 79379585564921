function Loader(): JSX.Element {
  return (
    <div className="relative flex-1 flex h-16 justify-center mt-4">
      <div className="absolute top-0">
        <svg
          className="animate-spin h-12 w-12 rounded-full bg-transparent border-4 border-secondary"
          style={{ borderRight: "white", borderTop: "white" }}
          viewBox="0 0 24 24"
        />
      </div>
    </div>
  );
}

export default Loader;
