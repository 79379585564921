import { useQuery } from "@tanstack/react-query";
import SoccerIcon from "../../../assets/icons/soccer.svg";
import { Jackpot } from "../../../models/jackpot";
import { useAppDispatch } from "../../../state/hooks";
import { addJackpot } from "../../../state/reducers/jackpotReducers";
import { handleGetRequest } from "../../../utils/post_api";
import Loader from "../../universal/Loader";
import RadioGroup from "./Checkbox";

export default function Index() {
  const dispatch = useAppDispatch();

  const games = async (): Promise<Jackpot[]> => {
    const data = await handleGetRequest<Jackpot[]>("jackpots/active");
    dispatch(addJackpot(data.data[0]));
    return data.data;
  };

  const { isLoading, data, isFetching } = useQuery({
    queryKey: ["jackpots"],
    queryFn: games,
  });

  if (isLoading || isFetching) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }
  return (
    <div className="h-full">
      {data![0] ? (
        data![0].games.map((game) => (
          <div
            className="flex justify-between px-2 py-2 items-center border-b"
            key={game.gameID}
          >
            <div className="grid grid-cols-1">
              <h2 className="text-[8px]">{game.tournament}</h2>
              <div className="font-bold">
                <h1 className="text-gray-700 text-sm">{game.homeTeam}</h1>
                <h1 className="text-gray-700 text-sm">{game.awayTeam}</h1>
              </div>
              <div className="flex gap-2 text-[8px]">
                <h1>Game ID: {game.gameID}</h1>
                <h1>22/03 19:00</h1>
              </div>
            </div>
            <div className="flex gap-1">
              <RadioGroup game={game} />
              {/* <div className="w-12 h-10 bg-primaryDark text-white font-bold flex items-center justify-center shadow rounded">
                  2.50
                </div>
                <div className="w-12 h-10 bg-white text-gray-700 font-bold flex items-center justify-center shadow-sm rounded">
                  1.2
                </div>
                <div className="w-12 h-10 bg-white text-gray-700 font-bold flex items-center justify-center shadow-sm rounded">
                  4.1
                </div> */}
            </div>
          </div>
        ))
      ) : (
        <div className="flex flex-col flex-1 justify-center items-center h-full bg-white gap-4">
          <img src={SoccerIcon} alt="Football" className="h-16" />
          <h1>No Jackpot available</h1>
          <a
            href="https://www.kwikbet.co.ke/sportsbook/#/"
            className="py-4 w-48 border border-secondary bg-secondary rounded text-center font-bold text-xs"
          >
            Place Live Bet
          </a>
        </div>
      )}
    </div>
  );
}
