import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface LoggedInUser {
  phoneNumber: string;
  isLoggedIn?: boolean;
}

const initialState: LoggedInUser = {
  phoneNumber: "",
  isLoggedIn: false,
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<LoggedInUser>) => {
      const stateCopy = state;
      stateCopy.phoneNumber = action.payload.phoneNumber;
      stateCopy.isLoggedIn = action.payload.isLoggedIn;
      return stateCopy;
    },
    removeUser: (state) => {
      const stateCopy = state;
      stateCopy.phoneNumber = "";
      stateCopy.isLoggedIn = false;
      return stateCopy;
    },
  },
});

export const { addUser, removeUser } = userReducer.actions;
export default userReducer.reducer;
