import { JackpotGame } from "../../../models/jackpot";
import useGamesUtilities from "../../../utils/gamesUtils";

interface RadioGroupProps {
  game: JackpotGame;
}

export default function RadioGroup({ game }: RadioGroupProps) {
  const { handleOptionChange, shouldBeChecked } = useGamesUtilities();

  return (
    <div className="flex space-x-2">
      <button
        type="button"
        className={`py-2 px-4 rounded ${
          shouldBeChecked(game, "1")
            ? "w-12 h-10 bg-primaryDark text-white font-bold flex items-center justify-center shadow rounded"
            : "w-12 h-10 bg-white text-gray-700 font-bold flex items-center justify-center shadow-sm rounded"
        }`}
        onClick={() => {
          handleOptionChange("1", game);
        }}
      >
        {game.homeOdds}
      </button>
      <button
        type="button"
        className={`py-2 px-4 rounded ${
          shouldBeChecked(game, "X")
            ? "w-12 h-10 bg-primaryDark text-white font-bold flex items-center justify-center shadow rounded"
            : "w-12 h-10 bg-white text-gray-700 font-bold flex items-center justify-center shadow-sm rounded"
        }`}
        onClick={() => {
          handleOptionChange("X", game);
        }}
      >
        {game.drawOdds}
      </button>
      <button
        type="button"
        className={`py-2 px-4 rounded ${
          shouldBeChecked(game, "2")
            ? "w-12 h-10 bg-primaryDark text-white font-bold flex items-center justify-center shadow rounded"
            : "w-12 h-10 bg-white text-gray-700 font-bold flex items-center justify-center shadow-sm rounded"
        }`}
        onClick={() => {
          handleOptionChange("2", game);
        }}
      >
        {game.awayOdds}
      </button>
    </div>
  );
}
