import axios, { AxiosResponse } from "axios";
import { PostData } from "./request_models";

interface RequestResponse<T> {
  data: T;
}

const apiBaseUrl: string =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_JACKPOT_API_URL!
    : process.env.REACT_APP_JACKPOT_API_URL!;

async function handlePostRequest<T>(
  url: string,
  data: PostData<T>,
): Promise<RequestResponse<T>> {
  try {
    const response: AxiosResponse<RequestResponse<T>> = await axios.post(
      apiBaseUrl + url,
      data.data,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
async function handleGetRequest<T>(
  url: string,
  params?: PostData<T>,
): Promise<AxiosResponse<T>> {
  try {
    const response: AxiosResponse = await axios.get(apiBaseUrl + url, params);
    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export { handleGetRequest, handlePostRequest };
