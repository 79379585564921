import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import DrawerReducer from "./reducers/drawerReducer";
import JackpotReducer from "./reducers/jackpotReducers";
import SlipReducer from "./reducers/slipReducer";
import UserReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
  user: UserReducer,
  slips: SlipReducer,
  jackpots: JackpotReducer,
  drawer: DrawerReducer,
});

const persistConfig = {
  key: "kwikbet-jackpot",
  storage,
  blacklist: ["drawer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
