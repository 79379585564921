import { JackpotGame, SlipEvent } from "../models/jackpot";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import {
  addGames,
  deleteGames,
  modifyGames,
} from "../state/reducers/slipReducer";

export default function useGamesUtilities() {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector((state) => state.jackpots);
  const betSlip = useAppSelector((state) => state.slips);
  const handleAutofill = (): void => {
    dispatch(deleteGames());
    const placeHolder = ["1", "X", "2"];
    games.forEach((game) => {
      const slip: SlipEvent = {
        eventCode: game.gameID,
        choice: placeHolder[Math.floor(Math.random() * 3)],
        homeTeam: game.homeTeam,
        homeOdds: game.homeOdds,
        awayTeam: game.awayTeam,
        awayOdds: game.awayOdds,
        drawOdds: game.drawOdds,
      };
      dispatch(addGames(slip));
    });
  };

  const handleRemoveAll = (): void => {
    dispatch(deleteGames());
  };

  const handleOptionChange = (option: string, game: JackpotGame) => {
    const slip: SlipEvent = {
      eventCode: game.gameID,
      choice: option,
      homeTeam: game.homeTeam,
      homeOdds: game.homeOdds,
      awayTeam: game.awayTeam,
      awayOdds: game.awayOdds,
      drawOdds: game.drawOdds,
    };
    // Check if game is already on betSlip
    const oldSlipIndex = betSlip.findIndex(
      (event) => event.eventCode === game.gameID,
    );

    // Add it to the list of games if it already doesn't exist
    if (oldSlipIndex === -1) {
      dispatch(addGames(slip));
    } else {
      const slips = [...betSlip];
      slips[oldSlipIndex] = slip;
      dispatch(modifyGames(slips));
    }
  };

  const shouldBeChecked = (
    gameToCheck: JackpotGame,
    choice: string,
  ): boolean => {
    const index = betSlip.findIndex((e) => e.eventCode === gameToCheck.gameID);
    if (index === -1) {
      return false;
    }
    const selected = betSlip.find((e) => e.eventCode === gameToCheck.gameID);
    if (!selected) return false;

    return selected?.choice === choice;
  };

  return {
    handleAutofill,
    handleRemoveAll,
    handleOptionChange,
    shouldBeChecked,
  };
}
