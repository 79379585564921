/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserSlip } from "../../../models/jackpot";
import { useAppSelector } from "../../../state/hooks";
import Loader from "../../universal/Loader";

export default function Index() {
  const user = useAppSelector((state) => state.user);

  const [selectedJackpot, setSelectedJackpot] = useState<number>();
  const [selectedResults, setSelectedResults] = useState<string>("all");
  const navigate = useNavigate();

  const userSlips = async (): Promise<UserSlip[]> => {
    const data = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_JACKPOT_API_URL}user/slips`,
      data: { phoneNumber: user.phoneNumber },
    });

    return data.data;
  };

  const { isLoading, data, isFetching } = useQuery({
    queryKey: ["myBets"],
    queryFn: userSlips,
    staleTime: 60 * 60 * 1000,
    enabled: user.isLoggedIn,
  });

  if (isFetching || isLoading) {
    return <Loader />;
  }

  if (!user.isLoggedIn) {
    return (
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        className="font-primary py-4 font-bold text-center text-xs lg:w-6/12"
      >
        <p>Please login to view your placed bets</p>
        <button
          type="button"
          onClick={() => navigate("/login")}
          className="px-6 py-2 lg:bg-primary mt-4 rounded font-primary font-bold text-white hover:bg-secondary bg-secondary"
        >
          Login
        </button>
      </motion.div>
    );
  }

  if (data!.length < 1) {
    return (
      <div className="font-primary font-bold text-center text-xs py-4 lg:w-6/12">
        <p>Place your bets to view them here</p>
        <button
          type="button"
          onClick={() => navigate("/")}
          className="px-6 py-2 lg:bg-primary mt-4 rounded font-primary font-bold text-white hover:bg-secondary bg-secondary"
        >
          Place Bet
        </button>
      </div>
    );
  }

  const handleSelectJackpot = (id: number): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    id === selectedJackpot ? setSelectedJackpot(0) : setSelectedJackpot(id);
  };
  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const items = {
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        ease: "easeInOut",
      },
    }),
    hidden: { opacity: 0, y: -50 },
  };

  const exit = { opacity: 0 };

  // eslint-disable-next-line array-callback-return, consistent-return
  const filteredData = data!.filter((result) => {
    if (
      (result.status === "LOST" || result.status === "WON") &&
      selectedResults === "resulted"
    ) {
      return result;
    }
    if (result.status === "PENDING" && selectedResults === "pending") {
      return result;
    }
    if (result.status === "NOT PLACED" && selectedResults === "notPlaced") {
      return result;
    }
  });

  return (
    <motion.div
      initial={{ opacity: 0.3 }}
      animate={{ opacity: 1 }}
      className="flex flex-col h-full lg:w-6/12 py-2"
    >
      <div>
        <div className="w-full flex justify-center">
          <div className="w-10/12 flex justify-between font-primary font-bold text-sm items-center mb-4">
            <h1>
              {selectedResults.replace(/^./, function (str) {
                return str.toUpperCase();
              })}{" "}
              Slips
            </h1>
            <select
              name="Slips"
              id=""
              defaultValue={selectedResults}
              className="py-1 px-2 bg-transparent border border-secondary rounded"
              onChange={(e: any) => setSelectedResults(e.target.value)}
            >
              <option value="all">All</option>
              <option value="resulted">Resulted</option>
              <option value="notPlaced">Not Placed</option>
              <option value="pending">Pending</option>
            </select>
          </div>
        </div>
        {selectedResults === "all" ? (
          data?.map((slip) => {
            return (
              <AnimateSharedLayout>
                <motion.div
                  key={slip.id}
                  className=" flex flex-col w-full items-center mt-1"
                >
                  <div
                    role="presentation"
                    className=" shadow-md py-3 w-10/12 flex flex-col items-center cursor-pointer"
                    onClick={() => handleSelectJackpot(slip.id)}
                  >
                    <div className="w-full flex justify-between">
                      <div>
                        <span className="font-primary font-light text-xs lg:text-sm px-4">
                          {slip.jackpotName} -{" "}
                          {moment(slip.createdAt).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div>
                        <span className="font-primary font-light text-[8px] px-4">
                          {slip.status}
                        </span>
                      </div>
                    </div>
                    {selectedJackpot === slip.id && (
                      <AnimatePresence>
                        <motion.div
                          layout
                          variants={list}
                          animate="visible"
                          initial="hidden"
                          exit={exit}
                          className="w-10/12 py-2 lg:4/12 divide-y "
                        >
                          {slip.events.map((game, i) => {
                            return (
                              <motion.div
                                variants={items}
                                custom={i}
                                exit={exit}
                                key={game.gameID}
                                className="px-2 py-1 flex flex-col"
                              >
                                <div className="text-left flex w-full justify-between items-center">
                                  <div>
                                    <p className="font-bold font-primary text-xs lg:text-xs py-1">{`${game.homeTeam} - ${game.awayTeam}`}</p>
                                  </div>
                                  <div
                                    className={
                                      game.result === game.choice
                                        ? "w-2 h-2 bg-green-400 rounded-full"
                                        : game.result === ""
                                        ? "w-2 h-2 bg-gray-400 rounded-full"
                                        : "w-2 h-2 bg-red-400 rounded-full"
                                    }
                                  />
                                </div>
                                <div className="lg:-mt-1 lg:text-xs">
                                  <p className="font-light font-primary text-xs lg:text-xs py-1">
                                    Result :{" "}
                                    {game.result === "1"
                                      ? game.homeTeam
                                      : game.result === "X"
                                      ? "Draw"
                                      : game.result === ""
                                      ? "Pending"
                                      : game.awayTeam}
                                  </p>
                                </div>
                                <div className="lg:-mt-1">
                                  <p className="font-light font-primary text-xs lg:text-xs py-1">
                                    Your Pick : {game.choice}
                                  </p>
                                </div>
                              </motion.div>
                            );
                          })}
                        </motion.div>
                      </AnimatePresence>
                    )}
                  </div>
                </motion.div>
              </AnimateSharedLayout>
            );
          })
        ) : filteredData.length === 0 ? (
          <h1 className="w-full text-center font-primary font-bold">
            You don&apos;t have any {selectedResults} slips
          </h1>
        ) : (
          filteredData?.map((slip) => {
            return (
              <AnimateSharedLayout>
                <motion.div
                  key={slip.id}
                  className=" flex flex-col w-full items-center mt-1"
                >
                  <div
                    role="presentation"
                    className=" shadow-md py-3 w-10/12 flex flex-col items-center cursor-pointer"
                    onClick={() => handleSelectJackpot(slip.id)}
                  >
                    <div className="w-full flex justify-between">
                      <div>
                        <span className="font-primary font-light text-xs lg:text-sm px-4">
                          {slip.jackpotName} -{" "}
                          {moment(slip.createdAt).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div>
                        <span className="font-primary font-light text-xxs px-4">
                          {slip.status}
                        </span>
                      </div>
                    </div>
                    {selectedJackpot === slip.id && (
                      <AnimatePresence>
                        <motion.div
                          layout
                          variants={list}
                          animate="visible"
                          initial="hidden"
                          exit={exit}
                          className="w-10/12 py-2 lg:4/12 divide-y "
                        >
                          {slip.events.map((game, i) => {
                            return (
                              <motion.div
                                variants={items}
                                custom={i}
                                exit={exit}
                                key={game.gameID}
                                className="px-2 py-1 flex flex-col"
                              >
                                <div className="text-left flex w-full justify-between items-center">
                                  <div>
                                    <p className="font-bold font-primary text-xs lg:text-xs py-1">{`${game.homeTeam} - ${game.awayTeam}`}</p>
                                  </div>
                                  <div
                                    className={
                                      game.result === game.choice
                                        ? "w-2 h-2 bg-green-400 rounded-full"
                                        : game.result === ""
                                        ? "w-2 h-2 bg-gray-400 rounded-full"
                                        : "w-2 h-2 bg-red-400 rounded-full"
                                    }
                                  />
                                </div>
                                <div className="lg:-mt-1 lg:text-xs">
                                  <p className="font-light font-primary text-xs lg:text-xs py-1">
                                    Result :{" "}
                                    {game.result === "1"
                                      ? game.homeTeam
                                      : game.result === "X"
                                      ? "Draw"
                                      : game.result === ""
                                      ? "Pending"
                                      : game.awayTeam}
                                  </p>
                                </div>
                                <div className="lg:-mt-1">
                                  <p className="font-light font-primary text-xs lg:text-xs py-1">
                                    Your Pick : {game.choice}
                                  </p>
                                </div>
                              </motion.div>
                            );
                          })}
                        </motion.div>
                      </AnimatePresence>
                    )}
                  </div>
                </motion.div>
              </AnimateSharedLayout>
            );
          })
        )}
      </div>
    </motion.div>
  );
}
