import { motion } from "framer-motion";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import pageVariants, { pageTransitions } from "../universal/Animations";

function SignUp() {
  return (
    <motion.main
      className="bg-primary h-screen w-screen"
      variants={pageVariants}
      transition={pageTransitions}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <header className="w-full grid grid-cols-1 justify-items-center gap-8 py-20">
        <img src={Logo} alt="Logo" className="w-1/3" />
        <h1 className="text-white">Signup</h1>
      </header>
      <section className="w-full grid justify-items-center">
        <form className="w-10/12 grid-cols-1 gap-12 text-white">
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2">
            <h1 className="text-sm">Mobile Number</h1>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <PhoneInput
              country="ke"
              inputStyle={{
                backgroundColor: "#3A0149",
                border: "none",
                marginLeft: 12,
              }}
              inputClass="py-4 text-sm"
              containerStyle={{ backgroundColor: "#3A0149", border: "none" }}
              containerClass="py-2 rounded"
              dropdownStyle={{ color: "black" }}
              dropdownClass="w-full"
              searchClass="w-12 flex justify-center"
            />
          </label>
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2 mt-4">
            <h1 className="text-sm">Password</h1>
            <input
              type="password"
              name="password"
              id=""
              className="py-4 rounded bg-primaryDark px-4 placeholder:text-sm border-none"
              placeholder="Enter a 4 digit password"
            />
          </label>
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2 mt-4">
            <h1 className="text-sm">Confirm Password</h1>
            <input
              type="password"
              name="password"
              id=""
              className="py-4 rounded bg-primaryDark px-4 placeholder:text-sm border-none"
              placeholder="Enter a 4 digit password"
            />
          </label>
          <label
            htmlFor="LoggedIn"
            className="flex w-full justify-start gap-2 items-start mt-2"
          >
            <input
              type="checkbox"
              name="keepSession"
              id=""
              className="h-4 w-4 text-primaryDark rounded border-2 border-primary"
            />
            <h1 className="text-xs">
              By registering for an account, you agree to our Terms &
              Conditions. You must be 18 years and Above to Join Kwikbet
            </h1>
          </label>
          <div className="w-full grid grid-cols-1 justify-items-center">
            <button
              type="button"
              className="bg-white text-black w-1/3 py-1 rounded mt-4 bg-gradient-to-b from-secondary to-secondaryLight"
            >
              Join
            </button>
          </div>
        </form>
      </section>

      <Link
        to="/login"
        className="w-full flex justify-center absolute bottom-8 text-white text-xs"
      >
        Already have an Account?
        <button type="button" className="text-secondary pl-1 font-bold">
          Login Here
        </button>
      </Link>
    </motion.main>
  );
}

export default SignUp;
