import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnimatedRoutes from "./AnimatedRoutes";

function App() {
  return (
    <div className="overflow-x-hidden relative flex lg:justify-center font-primary">
      <ToastContainer />
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  );
}

export default App;
