import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";

export default function ResetCode() {
  const navigate = useNavigate();
  const handleSendCode = () => navigate("/confirm-code");

  return (
    <main className="bg-primary h-screen w-screen relative">
      <header className="w-full grid grid-cols-1 justify-items-center gap-8 py-20">
        <img src={Logo} alt="Logo" className="w-1/3" />
        <h1 className="text-white">Reset Code</h1>
      </header>
      <section className="w-full grid justify-items-center">
        <form className="w-10/12 grid-cols-1 gap-12 text-white">
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2">
            <h1 className="text-sm">Mobile Number</h1>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <PhoneInput
              country="ke"
              inputStyle={{
                backgroundColor: "#3A0149",
                border: "none",
                marginLeft: 12,
              }}
              inputClass="py-4 text-sm"
              containerStyle={{ backgroundColor: "#3A0149", border: "none" }}
              containerClass="py-2 rounded"
              dropdownStyle={{ color: "black" }}
              dropdownClass="w-full"
              searchClass="w-12 flex justify-center"
            />
          </label>
          <div className="w-full grid grid-cols-1 justify-items-center">
            <button
              type="button"
              className="bg-white text-black w-1/3 py-1 rounded mt-4 bg-gradient-to-b from-secondary to-secondaryLight"
              onClick={handleSendCode}
            >
              Send Code
            </button>
          </div>
        </form>
      </section>
    </main>
  );
}
