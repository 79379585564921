import { Link } from "react-router-dom";

interface Iprops {
  name: string;
  icon: string;
  link?: string;
}
export default function BottomTabButton({ name, icon, link }: Iprops) {
  return (
    <Link
      to={link || "/"}
      className="flex flex-col justify-center items-center gap-1 relative"
    >
      <img src={icon} alt="tab icon" className="h-1/4 object-contain" />
      <h1 className="bottom-2">{name}</h1>
    </Link>
  );
}

BottomTabButton.defaultProps = {
  link: "/",
};
