import { Link, useNavigate } from "react-router-dom";
// import Search from "../../assets/icons/Icon awesome-search.svg";
import Menu from "../../assets/icons/Menu.svg";
import Search2 from "../../assets/icons/Search-two.svg";
import Logo from "../../assets/images/Logo.png";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { removeUser } from "../../state/reducers/userReducer";

export default function Navbar() {
  const { isLoggedIn } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <nav className="min-h-[64px] bg-primary flex px-2 items-center justify-between">
      <img src={Logo} alt="Logo" className="w-1/4 object-contain" />
      <div className="flex items-center gap-4">
        {!isLoggedIn ? (
          <Link
            to="/login"
            className="text-black text-sm py-1 font-bold bg-gradient-to-b from-secondary to-secondaryLight px-2 rounded flex items-center "
          >
            Login
          </Link>
        ) : (
          <button
            type="button"
            onClick={() => {
              dispatch(removeUser());
              navigate("/login");
            }}
            className="text-black text-sm py-1 font-bold bg-gradient-to-b from-secondary to-secondaryLight px-2 rounded flex items-center "
          >
            Logout
          </button>
        )}
        {/* <img src={Search} alt="search" className="h-4" /> */}
        <img src={Search2} alt="search" className="h-4" />
        <img src={Menu} alt="search" className="h-3" />
      </div>
    </nav>
  );
}
