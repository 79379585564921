import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Components/Body/Home";
import Index from "./Components/Body/mybets/Index";
import ConfirmationCode from "./Components/auth/ConfirmationCode";
import Login from "./Components/auth/Login";
import ResetCode from "./Components/auth/ResetCode";
import ResetPassword from "./Components/auth/ResetPassword";
import SignUp from "./Components/auth/SignUp";
import Betslip from "./Components/universal/games/BetSlip";
import Base from "./Layout/Base";

export default function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Base />}>
          <Route path="/" element={<Home />} />
          <Route path="/my-bets" element={<Index />} />
          <Route path="/slip" element={<Betslip />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetCode />} />
        <Route path="/confirm-code" element={<ConfirmationCode />} />
        <Route path="/set-password" element={<ResetPassword />} />
      </Routes>
    </AnimatePresence>
  );
}
