import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";

export default function ConfirmationCode() {
  const navigate = useNavigate();
  const handleReset = () => {
    navigate("/set-password");
  };
  return (
    <main className="bg-primary h-screen w-screen relative">
      <header className="w-full grid grid-cols-1 justify-items-center gap-8 py-20">
        <img src={Logo} alt="Logo" className="w-1/3" />
        <h1 className="text-white">Confirmation Code</h1>
      </header>
      <section className="w-full grid justify-items-center">
        <form className="w-10/12 grid-cols-1 gap-12 text-white">
          <label htmlFor="mobile" className="grid grid-cols-1 gap-2">
            <h1 className="text-sm">Confirmation Code</h1>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <input
              type="text"
              className="py-4  rounded bg-primaryDark placeholder:text-xs px-2 border-none"
              placeholder="Enter Code Sent to your phone "
            />
          </label>
          <div className="w-full grid grid-cols-1 justify-items-center">
            <button
              type="button"
              className="bg-white text-black w-1/3 py-1 rounded mt-4 bg-gradient-to-b from-secondary to-secondaryLight"
              onClick={handleReset}
            >
              Send Code
            </button>
          </div>
        </form>
      </section>
    </main>
  );
}
