import { createSlice } from "@reduxjs/toolkit";

interface DrawerState {
  open: boolean;
}

const initialState: DrawerState = {
  open: false,
};

const drawerSlice = createSlice({
  name: "drawerSlice",
  initialState,
  reducers: {
    openDrawer: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.open = true;
      return state;
    },
    closeDrawer: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.open = false;
      return state;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
