import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useNavigate } from "react-router-dom";
import MyBets from "../../assets/icons/Icon awesome-copyright.svg";
import Home from "../../assets/icons/icon-house.svg";
import { useAppSelector } from "../../state/hooks";
import useDrawerUtils from "../../utils/drawerUtils";
import BottomTabButton from "./BottomTabButton";
import Betslip from "./games/BetSlip";

export default function BottomTab() {
  const slips = useAppSelector((state) => state.slips);
  const navigate = useNavigate();
  const { open, hideDrawer } = useDrawerUtils();
  // const { handleAutofill, handleRemoveAll } = useGamesUtilities();
  // const location = useLocation();
  return (
    <footer className="flex flex-col h-14 overflow-hidden  bottom-0 bg-red-100 w-full text-sm text-white place-items-center">
      {/* {location.pathname === "/" && (
        <div className="w-full py-1 bg-secondary bg-opacity-90 flex justify-between px-4">
          <button
            type="button"
            className="text-gray-800"
            onClick={handleRemoveAll}
          >
            Clear
          </button>
          <button
            type="button"
            className="text-gray-800 font-bold"
            onClick={handleAutofill}
          >
            Auto select
          </button>
        </div>
      )} */}
      <div className="w-full  flex-1 grid grid-cols-3  bg-primary">
        <BottomTabButton name="Home" icon={Home} link="/" />
        <div className="bg-gradient-to-b from-secondary to-secondaryLight flex flex-col items-center justify-center text-black font-bold">
          <button
            type="button"
            onClick={() => navigate("/slip")}
            className="border border-black rounded-full h-6 w-6 flex justify-center items-center"
          >
            {slips.length}
          </button>
          Betslip
        </div>
        <BottomTabButton name="My Bets" icon={MyBets} link="/my-bets" />
        {open && (
          <Drawer
            open
            onClose={hideDrawer}
            direction="bottom"
            size="50vh"
            className="rounded shadow-md"
            lockBackgroundScroll
          >
            <Betslip />
          </Drawer>
        )}
        {/* <BottomTabButton name="Freebet" icon={FreeBet} link="/freebet" /> */}
        {/* <BottomTabButton name="Account" icon={Account} link="/account" /> */}
      </div>
    </footer>
  );
}
