import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { closeDrawer, openDrawer } from "../state/reducers/drawerReducer";

export default function useDrawerUtils() {
  const dispatch = useAppDispatch();
  const { open } = useAppSelector((state) => state.drawer);

  const showDrawer = useCallback(() => {
    dispatch(openDrawer());
  }, [dispatch]);

  const hideDrawer = useCallback(() => {
    dispatch(closeDrawer());
  }, [dispatch]);

  return {
    showDrawer,
    hideDrawer,
    open,
  };
}
